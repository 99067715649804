$blueColor: var(--ui-color-primary);
$greenColor: #2cbc45;
$redColor: var(--ui-color-alert);

$darkColor: var(--ui-color-first);
$darkBorderColor: #5d5d5d;

$mainTextColor: var(--ui-color-first);
$subTextColor: var(--ui-color-grey-61);

@import '/src/styles/graph';

@import './modules/campaigns/styles/index';

.notification-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    max-width: 15rem;
    display: inline-block;
}

.breadcrumb-popover {
    margin: 0 !important;
    color: var(--ui-color-text);
    div {
        padding: 0.8rem !important;
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20rem;
        white-space: nowrap;
        span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
