@import 'mixins';

/**
* Progress bar animations
*/
@-webkit-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-moz-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-ms-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-o-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}
