.flex-space {
    flex-grow: 1;
}
.muted {
    color: var(--ui-color-grey-61);
}
.dialog-buttons {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    ui-button {
        min-width: 21rem;
    }

    ui-button + ui-button {
        margin-left: var(--default-margin);
    }
}
.panel {
    padding: var(--default-padding);
    border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    background-color: var(--ui-color-background-second);
    border: var(--ui-border);
}
.label {
    margin-bottom: 1.7rem;
}
.text-capitalize {
    text-transform: capitalize;
}
.ad-status {
    &.not-published {
        color: var(--ui-color-grey-61);
    }
    &.publish-failed {
        display: flex;
        align-items: center;

        ui-svg-icon {
            color: var(--ui-color-alert);
            font-size: 1.6rem;
            margin-right: 1rem;
            cursor: pointer;
        }
    }
    &.publishing {
        color: var(--ui-color-primary);
    }
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ui-list {
    .row-actions {
        flex-grow: 1;
        text-align: right;
        .action-icon {
            display: inline-flex;
            height: 2.4rem;
            width: 2.4rem;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: var(--ui-color-grey-61);
            opacity: 0;
            transition-property: color, opacity;
            transition-duration: 0.2s;
            transition-timing-function: linear;
            cursor: pointer;
            &:active,
            &:focus,
            &:hover,
            &.is-highlighted,
            &.popover-active {
                opacity: 1;
            }
        }
    }
    .row-action + .row-action {
        margin-left: 1rem;
    }
    ::ng-deep {
        cdk-tree-node {
            &:hover {
                .row-actions .action-icon {
                    opacity: 1 !important;
                }
            }
        }
    }
}
::ng-deep ui-confirm-dialog,
::ng-deep ui-alert-dialog {
    .text {
        padding: 0 !important;
    }
    .warning {
        background-color: var(--ui-color-grey-98);
        border: 1px solid var(--ui-color-grey-84);
        padding: var(--default-padding-small);
        display: flex;
        align-items: center;
    }
    .warning-icon {
        margin-right: 1rem;
        min-width: 3.4rem;
        height: 3.4rem;
        display: block;
        float: left;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjM0IiBoZWlnaHQ9IjM0IiB2aWV3Qm94PSIwIDAgMTA4OCAxMDg4Ij48dGl0bGU+PC90aXRsZT48cGF0aCBmaWxsPSIjOWE5YTlhIiBkPSJNNTQ0IDEwODhjLTMwMC40NDMgMC01NDQtMjQzLjU1Ny01NDQtNTQ0czI0My41NTctNTQ0IDU0NC01NDRjMzAwLjQ0MyAwIDU0NCAyNDMuNTU3IDU0NCA1NDRzLTI0My41NTcgNTQ0LTU0NCA1NDR6TTU0NCAxMDM3YzI3Mi4yNzYgMCA0OTMtMjIwLjcyNCA0OTMtNDkzcy0yMjAuNzI0LTQ5My00OTMtNDkzYy0yNzIuMjc2IDAtNDkzIDIyMC43MjQtNDkzIDQ5M3MyMjAuNzI0IDQ5MyA0OTMgNDkzek01NDQgNjQ2Yy0zNy41NTUgMC02OC0zMC40NDUtNjgtNjh2LTI3MmMwLTM3LjU1NSAzMC40NDUtNjggNjgtNjhzNjggMzAuNDQ1IDY4IDY4djI3MmMwIDM3LjU1NS0zMC40NDUgNjgtNjggNjh6TTU0NCA4NTBjLTM3LjU1NSAwLTY4LTMwLjQ0NS02OC02OHMzMC40NDUtNjggNjgtNjhjMzcuNTU1IDAgNjggMzAuNDQ1IDY4IDY4cy0zMC40NDUgNjgtNjggNjh6Ij48L3BhdGg+PC9zdmc+');
    }
}
