$graphColor: (
    'white': var(--ui-color-surface),
    'tickFontSize': 11px,
    'axisTextColor': var(--ui-color-grey-61),
    'gridColor': #dedede,
    'tooltipBackground': var(--ui-color-black),
    'pieColorBase': var(--ui-color-primary)
);

$lineColors: #dc5b5b, #d18f22, #5f8153, #7ec2b9, #817baf, #a5a392, #567e94, #946767, #a53188, #d3a8b6;
$deviceColors: #1b75dd, #93c1e7, #03aaff, #817baf, #d5c8da;
$pieColors: #1b75dd, lighten(#1b75dd, 15), lighten(#1b75dd, 30);

.c3 {
    $lineThickness: 2px;
    $dotSize: 5px;
    $textSize: var(--ui-font-size);

    $graphFontWeight: (
        'bold': 700,
        'semiBold': 600,
        'regular': 400
    );

    @import 'graphTooltip';
    svg {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 12px;
        color: var(--ui-color-grey-61);

        g.c3-target {
            transition: opacity 0.2s ease;

            @each $value in $lineColors {
                &.c3-target-custom#{i} {
                    .c3-circle {
                        stroke: $value;
                    }
                }
            }
        }

        .c3-lines {
            .c3-line {
                stroke-width: $lineThickness;
            }
        }

        .c3-circles {
            circle {
                transition:
                    fill 0.2s ease,
                    transform 0.2s ease;
                stroke-width: $lineThickness;
            }
        }

        .c3-axis {
            .tick {
                text {
                    font-size: map-get($graphColor, 'tickFontSize');
                    fill: map-get($graphColor, 'axisTextColor');
                }

                line {
                    display: none;
                }
            }
        }

        .c3-ygrids,
        .c3-xgrids {
            line {
                stroke-width: 1px;
                stroke: map-get($graphColor, 'gridColor');
                transform: translate(-0.5px, -0.5px);
            }
        }

        g.c3-xgrid-focus {
            line {
                opacity: 0.5;
                stroke: map-get($graphColor, 'gridColor');
                stroke-width: 14px;
            }
        }

        g.c3-legend-item {
            text {
                &:before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: var(--ui-color-black);
                }
            }
        }

        g.c3-lines-Total {
            stroke-dasharray: 7, 3;
        }

        .domain {
            display: none;
        }
    }
}
