.c3-tooltip-container {
    background: var(--ui-color-black);
    padding: 1rem 0.5rem;
    opacity: 0.9;
    border-radius: 0.2rem;
    .c3-tooltip {
        border-collapse: collapse;
        border-spacing: 0;
        empty-cells: show;
        background: transparent;
        font-size: textSize;
        box-shadow: none;
        user-select: none;

        tbody {
            tr {
                border: none;

                th {
                    background-color: transparent;
                    padding: 5px 10px;
                    text-align: left;
                    color: var(--ui-color-surface);
                    font-size: textSize;
                    font-weight: var(--ui-font-weight-semibold);
                }

                td {
                    background-color: transparent;
                    color: var(--ui-color-surface);
                    padding: 2px 12px;
                    border-left: none;
                    font-size: textSize;
                    white-space: nowrap;
                    &.name {
                        padding-right: 0;

                        span {
                            border-radius: 100%;
                        }
                    }
                }
            }
        }
    }
}
